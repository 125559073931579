<template>
  <div class="confirmation-multiple">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.prevent.stop="handleModalRefresh(true)"
        >
          <font-awesome-icon icon="retweet" />
        </div>
      </template>
      <span>Générer des factures</span>
    </v-tooltip>
    <!-- CONFIRMATION Modal -->
    <v-dialog
      v-model="confirmModal"
      persistent
      :max-width="step == 0 ? '600px' : '1400px'"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Générer des factures </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal()"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <template v-if="step == 0">
          <v-card-text class="body-card">
            <form class="form-add">
              <v-row>
                <v-col cols="4">
                  <div class="title-card">
                    Type de document :
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-radio-group v-model="document" row>
                    <v-radio
                      v-for="option in options"
                      :key="option.value"
                      :label="option.text"
                      :value="option.value"
                      color="#704ad1"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
          <div class="message-error-modal" v-if="returnCountProject == 0">
            Le processus de géneration de facture selon le filtre peut prend un
            peu de temps
          </div>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
          <v-divider class="line-hr-footer"></v-divider>
          <v-card-actions class="actions-msg-btn-modal">
            <v-spacer></v-spacer>
            <v-btn
              color="#704ad1"
              text
              @click="changeConfirmationDossier()"
              :loading="loading"
              :disabled="loading"
              :class="{ loader: loading }"
            >
              Générer
            </v-btn>
            <v-btn text @click="hideModal()">
              Annuler
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text class="body-card">
            <div class="icon-download">
              <v-tooltip bottom color="#311B92">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="block-icon mr-2"
                    v-on="on"
                    v-bind="attrs"
                    @click.prevent.stop="downloadContentAutoFacture()"
                  >
                    <font-awesome-icon icon="cloud-download-alt" />
                  </div>
                </template>
                <span>Télécharger rapport</span>
              </v-tooltip>
            </div>

            <div id="divFactureAuto" ref="divFactureAuto">
              <div
                v-for="(msg, index) in message"
                :key="msg.num_dossier"
                :class="{
                  'card-generation-fact': index % 2 == 0,
                  'card-generation-fact-active': index % 2 != 0
                }"
                style="break-inside: avoid;"
              >
                <center>
                  <b class="mb-2"
                    >Numéro de dossier : {{ msg.numero_dossier }}</b
                  >
                </center>
                <div>
                  <div
                    class=" m-1 d-block"
                    v-if="msg && msg.deja_exisit && msg.deja_exisit.length"
                  >
                    <div>
                      <b class="title"
                        >Les supports suivant pour le dossier
                        {{ msg.numero_dossier }}
                        ont déjà des factures :
                      </b>
                    </div>

                    <div class="ml-3 body">
                      <ul v-if="Array.isArray(msg.deja_exisit)">
                        <li v-for="(e, index) in msg.deja_exisit" :key="index">
                          {{ e.objet }}({{ e.vendeur }}) : {{ e.error }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class=" m-1 d-block"
                    v-if="msg && msg.no_client && msg.no_client.length"
                  >
                    <div><b class="title">Client non trouvé : </b></div>
                    <br />
                    <div class="ml-3 body">
                      <ul v-if="Array.isArray(msg.no_client)">
                        <li
                          v-for="(e, index) in msg.no_client"
                          :key="index"
                          class="li-simulation-conf"
                        >
                          <div>{{ e.objet }} ( {{ e.vendeur }} )</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class=" m-1 d-block"
                    v-if="msg && msg.no_config && msg.no_config.length"
                  >
                    <div>
                      <b class="title">
                        Les supports suivants n'ont pas une configuration :
                      </b>
                    </div>
                    <div class="ml-3 body">
                      <ul v-if="Array.isArray(msg.no_config)">
                        <li v-for="(e, index) in msg.no_config" :key="index">
                          <div>{{ e }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                class=" m-1 d-block"
                v-if="msg && msg.empty_value && msg.empty_value.length"
              >
                <div>
                  <b class="title"> Factures avec un montant égale à zéro : </b>
                </div>
                <div class="ml-3 body">
                  <ul v-if="Array.isArray(msg.empty_value)">
                    <li v-for="(e, index) in msg.empty_value" :key="index">
                      <div>{{ e.objet }} ( {{ e.vendeur }} )</div>
                    </li>
                  </ul>
                </div>
              </div>
                  <div
                    class=" m-1 d-block"
                    v-if="
                      msg &&
                        msg.no_or_multiple_rib &&
                        msg.no_or_multiple_rib.length
                    "
                  >
                    <div>
                      <b class="title">
                        Les supports suivants n'ont pas un RIB où à plusieurs
                        RIB :</b
                      >
                    </div>
                    <div class="ml-3 body">
                      <ul v-if="Array.isArray(msg.no_or_multiple_rib)">
                        <li
                          v-for="(e, index) in msg.no_or_multiple_rib"
                          :key="index"
                        >
                          <div>{{ e.objet }}({{ e.vendeur }})</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class=" m-1 d-block"
                    v-if="msg && msg.no_sequence && msg.no_sequence.length"
                  >
                    <div>
                      <b class="title"> Des sociétes sans séquence :</b>
                    </div>
                    <div class="ml-3 body">
                      <ul v-if="Array.isArray(msg.no_sequence)">
                        <li v-for="(e, index) in msg.no_sequence" :key="index">
                          <div>{{ e }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class=" m-1 d-block"
                    v-if="msg && msg.generated && msg.generated.length"
                  >
                    <div><b class="title"> Les factures générées : </b></div>
                    <div class=" body">
                      <v-simple-table v-if="Array.isArray(msg.generated)">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center text-dark">Vendeur</th>
                              <th class="text-center text-dark">Objet</th>
                              <th class="text-center text-dark">
                                Numéro Facture
                              </th>
                              <th class="text-center text-dark">
                                Nombre des produits
                              </th>
                              <th class="text-center text-dark">
                                MT TTC facture
                              </th>
                              <th class="text-center text-dark">Template</th>
                              <th class="text-center text-dark">Nom client</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in msg.generated"
                              :key="'genereted' + index"
                            >
                              <td class="text-center">{{ item.vendeur }}</td>
                              <td class="text-center">{{ item.objet }}</td>
                              <td class="text-center">{{ item.num }}</td>
                              <td class="text-center">
                                {{ item.number_of_products }}
                              </td>
                              <td class="text-center">
                                {{ item.montant_ttc }} €
                              </td>
                              <td class="text-center">{{ item.template }}</td>
                              <td class="text-center">{{ item.client }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';

import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    filterConfirmation: { required: true },
    filter: { required: true }
  },
  data() {
    return {
      confirmModal: false,
      options: [
        {
          text: 'Facture',
          value: 'facture'
        },
        {
          text: 'Proforma',
          value: 'proforma'
        }
      ],
      document: 'proforma',
      error: null,
      loading: false,
      step: 0,
      message: null,
    };
  },
  computed: {
    returnCountProject() {
      return this.dataToUse.filter(item => item.check == true).length;
    }
  },
  methods: {
    ...mapActions([
      'refreshSimulation',
      'generationParFilterSimulation',
      'getFactureNumberSimulation'
    ]),
    handleModalRefresh(show) {
      if (show) {
        this.confirmModal = true;
      }
    },
    hideModal() {
      if (this.step == 1) {
        this.$emit('reloadData');
      }
      this.resetModal();
      this.confirmModal = false;
    },
    resetModal() {
      this.error = null;
      this.loading = false;
      this.step = 0;
    },
    async changeConfirmationDossier() {
      this.error = null;
      this.loading = true;
      var bodyFormData = new FormData();
      let response;
      let DataSToUse = this.dataToUse.filter(item => item.check == true);
      bodyFormData.append('document', this.document);

      if (DataSToUse.length > 0) {
        DataSToUse.forEach((element, index) => {
          bodyFormData.append(
            'factures[' + index + '][project_id]',
            element.project_id
          );
          bodyFormData.append(
            'factures[' + index + '][vendeur_id]',
            element.vendeur_id
          );
          bodyFormData.append('factures[' + index + '][objet]', element.objet);
          bodyFormData.append(
            'factures[' + index + '][master]',
            element.master
          );
        });
        response = await this.refreshSimulation(bodyFormData);
      } else {
        Object.keys(this.filter).forEach(key => {
          if (Array.isArray(this.filter[key])) {
            for (let i = 0; i < this.filter[key].length; i++) {
              bodyFormData.append(key + '[' + i + ']', this.filter[key][i]);
            }
          } else {
            bodyFormData.append(key, this.filter[key]);
          }
        });
        response = await this.generationParFilterSimulation(bodyFormData);
      }
      if (response.succes == true) {
        this.loading = false;
        this.message = response.message.data;
        this.step = 1;
      }
    },
    downloadContentAutoFacture() {
      html2pdf(this.$refs.divFactureAuto, {
        margin: 1,
        filename: 'rapport-facture.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'paysage' }
      });
    }
  },
  mounted() {}
};
</script>
<style scoped lang="scss">
.icon-download {
  display: flex;
    margin-left: 50%;
    color: #704ad1;
}
.title-card {
  font-weight: 500;
  padding-top: 7px;
  font-size: 14px;
  color: #704ad1;
}
.form-add {
  padding-top: 10px;
}

</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.card-generation-fact {
  border: 1px solid #e3e3e3;
  background-color: #f2f2f2;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .title_error {
    font-size: 13px;
    color: red;
  }
  .body {
    font-size: 11px;
  }
}
.card-generation-fact-active {
  background-color: #f6f5fb;
  border: 1px solid #e0ddf0;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .title_error {
    font-size: 13px;
    color: red;
  }
  .body {
    font-size: 11px;
  }
}
.export-facture-auto {
  font-size: 17px;
  left: 89%;
  position: relative;
}
</style>

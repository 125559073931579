<template>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="handleLockFacture"
        >
        <font-awesome-icon icon="lock"
    />        </div>
      </template>
      <span>Verrouillage des factures</span>
    </v-tooltip>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    dataToUse: { required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['lockGlobaleFactureSimulation']),
    async handleLockFacture() {
      this.$swal
        .fire({
          title:
            "Êtes-vous sur de vouloir modifier l'état de configuration de ces factures ? ",
          type: 'warning',
          fontFamily : 'Montserrat',
          icon: 'warning',
          locale: 'fr',
          allowOutsideClick: () => !this.$swal.isLoading(),
          stopKeydownPropagation: false,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn ',
          cancelButtonColor: '#d33',
          confirButtonColor: '#218838',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            let params = {
              ids: this.dataToUse
                .filter(item => item.check == true && item.facture_id)
                .map(i => i.facture_id),
              data: this.dataToUse.filter(item => item.check == true)
            };
            const response = await this.lockGlobaleFactureSimulation(params);
          }
        });
    }
  }
};
</script>
<style lang="scss" >
button.swal2-confirm.order-1.btn.btn-success.swal2-styled {
    background: #704ad1;
    border: none !important;
}
.swal2-styled.swal2-cancel:focus {
    box-shadow: 0 0 0 3px #704ad1 !important;
}
button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline , button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline:hover , button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline:focus {
    border: 1px solid;
    background-color: #fff !important;
    color: #704ad1 !important;
    border-color: #704ad1 !important;
}
h2#swal2-title {
    font-family: 'Montserrat';
}
.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
    width: 40%;
    border-radius: 10px !important;
}
</style>